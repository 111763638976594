<template>
  <div>
    <p
      @click="onClick"
      style="width:100vw;height:100vh;position:fixed;top:0;left:0;background-color:#fff;z-index:999999999"
    >
      click
    </p>
  </div>
</template>

<script>
export default {
  name: 'v4-page-publication',
  mounted() {},
  methods: {
    onClick() {
      window.location.href = 'chrome://version';
    },
  },
};
</script>
